import React, { Component } from "react"
import styles from "./index.module.scss"
import BackgroundImage from "gatsby-background-image"
import { StaticQuery, graphql } from "gatsby"
import Player from "@vimeo/player"
import Fade from "react-reveal/Fade"
import loadStates from "../../../../hocs/loadStates"

class Articles extends Component {
  render() {
    const { homeContent } = this.props
    return (
      <section
        id="home-articles"
        className="flex justify-center w-full relative border-white border-solid border-b-2 border-t-2 md:flex-col lg:border-none lg:h-auto"
      >
        {/* <ArticleOne homeContent={homeContent} locale={this.props.lang.locale} /> */}
        <ArticleTwo homeContent={homeContent} locale={this.props.lang.locale} />
      </section>
    )
  }
}

export default loadStates(Articles)

class ArticleOne extends Component {
  constructor() {
    super()

    this.video = null
    this.player = null
    this.header = null
    this.state = {
      isActive: false,
      containerStartHeight: 0,
    }
  }
  componentDidMount() {
    this.player = new Player(this.video, {
      id: "https://vimeo.com/410547544/e08465d350",
      background: true,
      responsive: true,
      autoplay: true,
      muted: true,
      loop: true,
    })

    this.registerContainerStartHeight()
    typeof window !== "undefined" &&
      window.addEventListener("resize", this.registerContainerStartHeight)
  }

  componentWillMount() {
    typeof window !== "undefined" &&
      window.removeEventListener("resize", this.registerContainerStartHeight)
  }

  registerContainerStartHeight = () => {
    this.header &&
      this.setState({ containerStartHeight: this.header.clientHeight })
  }
  pauseVideo = () => {
    this.player && this.player.pause()
  }
  playVideo = () => {
    this.player && this.player.play()
  }
  handleClick = () => {
    const { isActive } = this.state
    if (isActive) {
      this.setState({ isActive: false })
      this.player && this.player.play()
    } else {
      this.setState({ isActive: true })
      this.player && this.player.pause()
    }
  }
  render() {
    const { homeContent, locale } = this.props
    const { isActive, containerStartHeight } = this.state
    return (
      <article
        onMouseEnter={this.pauseVideo}
        onMouseLeave={this.playVideo}
        onClick={this.handleClick}
        className={`${styles.article} ${
          isActive ? styles.active : ""
        } w-6/12 relative border-white border-solid border-r-2 lg:w-full overflow-hidden px-24 py-24 lg:px-10 lg:py-16 lg:border-white lg:border-solid lg:border-b-2 lg:border-none`}
      >
        <Fade>
          <div className="flex justify-start h-full overflow-hidden">
            <div
              className={`${
                styles.contentContainer
              } flex flex-col h-full justify-between self-end ${
                locale === "tc" ? styles.isChinese : ""
              }`}
              style={{
                transform: `translateY(calc(100% - ${containerStartHeight}px))`,
              }}
            >
              <div className="">
                <h4
                  className="headline text-white mb-4 lg:flex sm:text-5xl"
                  ref={header => (this.header = header)}
                >
                  {homeContent.articleOneTitle}
                  <span
                    className={`w-16 hidden self-center lg:block lg:ml-4 ${
                      locale === "tc"
                        ? ""
                        : "md:self-start md:w-32 md:px-1 md:ml-0"
                    }`}
                  >
                    <svg
                      viewBox="0 0 512 512"
                      className="self-center fill-current"
                    >
                      <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 " />
                    </svg>
                  </span>
                </h4>
                <p className="text-white body sm:text-2xl">
                  {homeContent.articleOneSubTitle}
                </p>
                <p className="text-white body sm:text-2xl">
                  {homeContent.articleOneAuthor}
                </p>
              </div>
              <p className="text-white body sm:text-2xl">
                {homeContent.articleOneContent}
              </p>
            </div>
          </div>
          <div
            className={`${styles.videoBgContainer} absolute flex w-full h-full top-0 left-0 -z-10 overflow-hidden`}
          >
            <div
              className={`w-full self-center ${styles.gif}`}
              id="ci-gif"
              ref={video => (this.video = video)}
            />
          </div>
        </Fade>
      </article>
    )
  }
}

class ArticleTwo extends Component {
  constructor() {
    super()
    this.header = null
    this.state = {
      isActive: false,
      containerStartHeight: 0,
    }
  }
  componentDidMount() {
    this.registerContainerStartHeight()
    typeof window !== "undefined" &&
      window.addEventListener("resize", this.registerContainerStartHeight)
  }
  componentWillMount() {
    typeof window !== "undefined" &&
      window.removeEventListener("resize", this.registerContainerStartHeight)
  }

  registerContainerStartHeight = () => {
    this.header &&
      this.setState({ containerStartHeight: this.header.clientHeight })
  }

  handleClick = () => {
    const { isActive } = this.state
    isActive
      ? this.setState({ isActive: false })
      : this.setState({ isActive: true })
  }
  render() {
    const { homeContent, locale } = this.props
    const { isActive, containerStartHeight } = this.state
    return (
      <article
        ref={article => (this.articleTwo = article)}
        onClick={this.handleClick}
        className={`${styles.article} ${
          isActive ? styles.active : ""
        } w-full relative border-white border-solid border-r-2 lg:w-full overflow-hidden lg:border-white lg:border-solid lg:border-b-2 px-24 py-24 lg:px-10 lg:py-16 lg:border-none`}
      >
        <Fade>
          <div className="flex justify-start h-full overflow-hidden">
            <div
              className={`${styles.contentContainer} ${
                styles.contentContainerSecond
              } flex flex-col h-full justify-between self-end ${
                locale === "tc" ? styles.isChinese : ""
              }`}
              style={{
                transform: `translateY(calc(100% - ${containerStartHeight}px))`,
              }}
            >
              <div className="" ref={header => (this.header = header)}>
                <h5 className="large-text font-bold text-white uppercase mb-2 lg:flex">
                  <span className="self-center">
                    {homeContent.articleTwoSmallTitle}
                  </span>
                  <span className="w-16 hidden lg:block lg:self-center lg:ml-4">
                    <svg
                      viewBox="0 0 512 512"
                      className="self-center fill-current"
                    >
                      <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 " />
                    </svg>
                  </span>
                </h5>
                <h4 className="headline text-white mb-4 sm:text-5xl">
                  {homeContent.articleTwoTitle}
                </h4>
                <p className="text-white body sm:text-2xl">
                  {homeContent.articleTwoSubTitle}
                </p>
              </div>
              <p className="text-white body sm:text-2xl">
                {homeContent.articleTwoContent}
              </p>
            </div>
          </div>
          <div className="heroBgContainer absolute w-full h-full top-0 left-0 -z-10">
            <StaticQuery
              query={graphql`
                query ArticleQuery {
                  desktop: file(name: { eq: "home-banner" }) {
                    childImageSharp {
                      fluid(maxWidth: 4000, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  mobile: file(name: { eq: "home-banner-mobile" }) {
                    childImageSharp {
                      fluid(maxWidth: 3080, quality: 50) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              `}
              render={data => (
                <>
                <BackgroundImage
                  Tag="div"
                  className="w-full h-full md:hidden"
                  fluid={data.desktop.childImageSharp.fluid}
                  backgroundColor={`#040e18`}
                  loading="eager"
                  critical={true}
                />
                <BackgroundImage
                  Tag="div"
                  className="w-full h-full hidden md:block"
                  fluid={data.mobile.childImageSharp.fluid}
                  backgroundColor={`#040e18`}
                  loading="eager"
                  critical={true}
                />
                </>
              )}
            />
          </div>
        </Fade>
      </article>
    )
  }
}
