import React, { Component } from "react"
import styles from "./index.module.scss"
import Player from "@vimeo/player"
import { TimelineMax, Sine } from "gsap"
import ScrollToBtn from "../../../Button/ScrollTo"
import { Swipeable } from "react-swipeable"
import loadStates from "../../../../hocs/loadStates"

class Intro extends Component {
  constructor(props) {
    super(props)

    this.video = null
    this.tl = null
    this.blackBG = null
    this.title = null
    this.btn = null
    this.paragraph = null
    this.cta = null
    this.state = {
      videoIsReady: false,
      revealText: false,
    }
  }
  componentDidMount() {
    const player =
      typeof window !== "undefined" && window.innerWidth > 1024
        ? new Player(this.video, {
            id: "https://vimeo.com/410430680/9d26f71bde",
            background: true,
            responsive: true,
            autoplay: true,
            muted: true,
            loop: true,
          })
        : typeof window !== "undefined" && window.innerWidth <= 1024
        ? new Player(this.video, {
            id: "https://vimeo.com/410544894/5d29f20fd5",
            background: true,
            responsive: true,
            autoplay: true,
            muted: true,
            loop: true,
          })
        : null

    player.play().then(() => {
      this.setState({ videoIsReady: true })
    })

    this.tl = new TimelineMax({ paused: true })
      .to(this.blackBG, 1, {
        autoAlpha: 0,
        ease: Sine.easeOut,
      })
      .fromTo(
        this.title,
        1,
        { autoAlpha: 0 },
        { autoAlpha: 1, ease: Sine.easeOut }
      )
      .fromTo(
        this.paragraph,
        1,
        { autoAlpha: 0 },
        { autoAlpha: 1, ease: Sine.easeOut },
        "-=0.5"
      )
      .fromTo(
        this.btn,
        1,
        { autoAlpha: 0, y: -20 },
        { autoAlpha: 1, y: 0, ease: Sine.easeOut },
        "-=0.5"
      )
    // if (typeof window !== "undefined" && window.innerWidth <= 768) {
    //   // add listener to disable scroll
    //   window.addEventListener("scroll", this.noScroll)
    // }
  }

  noScroll = () => {
    window.scrollTo(0, 0)
  }

  componentDidUpdate() {
    if (this.state.videoIsReady) {
      this.tl.play()
    }
    // if (this.state.revealText) {
    //   // Remove listener to re-enable scroll
    //   window.removeEventListener("scroll", this.noScroll)
    //   if (typeof window !== "undefined" && window.innerWidth <= 768) {
    //     // Remove listener to re-enable scroll
    //     window.removeEventListener("scroll", this.noScroll)
    //   }
    // }
  }

  trackSwipeDirection = direction => {
    if (direction === "Up") {
      typeof window !== "undefined" &&
        window.innerWidth <= 768 &&
        this.setState({ revealText: true })
    }
  }
  render() {
    const { homeContent } = this.props
    const { revealText } = this.state

    const config = {
      delta: 10,
      preventDefaultTouchmoveEvent: false,
      trackTouch: true,
      trackMouse: false,
      rotationAngle: 0,
    }

    const active = revealText ? styles.active : ""
    const { locale } = this.props.lang
    const isChinese = locale === "tc" ? styles.isChinese : ""
    return (
      <>
        <Swipeable
          onSwiped={eventData => this.trackSwipeDirection(eventData.dir)}
          {...config}
        >
          <section
            className={`${styles.sectionHomeVideo} flex justify-center fullHeight w-full relative bg-black overflow-hidden`}
          >
            <div
              className={`w-10/12 relative z-10 ${styles.heroTitleContainerCol} md:flex md:overflow-hidden md:relative`}
            >
              <div
                className={`${styles.heroTitleContainer} absolute left-0 w-full md:self-end ${active} ${isChinese}`}
              >
                <h2
                  className="hero text-white uppercase mb-10 md:mb-6 opacity-0 light-font"
                  ref={title => (this.title = title)}
                >
                  <span className={`block ${styles.largeTitle}`}>
                    {homeContent.pageTitle}
                  </span>
                  <span className={`block md:text-4xl ${styles.smallTitle}`}>
                    {homeContent.introSubTitle}
                  </span>
                </h2>
                <div className="flex">
                  <div className="w-7/12 md:w-full">
                    <p
                      className={`text-white opacity-0 ${styles.introParagraph} ${isChinese}`}
                      ref={paragraph => (this.paragraph = paragraph)}
                    >
                      {homeContent.introParagraph}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`${styles.video} absolute w-full h-full top-0 left-0`}
              id="video"
              ref={video => (this.video = video)}
            />
            <div
              className={`absolute w-full h-full top-0 left-0 bg-black`}
              ref={bg => (this.blackBG = bg)}
            ></div>
            <div
              className={`${styles.btnContainer} z-20 opacity-0`}
              ref={btn => (this.btn = btn)}
            >
              <ScrollToBtn
                scrollTo="#latest-stories"
                text={homeContent.scrollForMore}
              />
            </div>
          </section>
        </Swipeable>
      </>
    )
  }
}

export default loadStates(Intro)
