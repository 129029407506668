import React from "react"
import styles from "./index.module.scss"
import gsap from "gsap"
import ScrollToPlugin from "gsap/ScrollToPlugin"

export default function ScrollToBtn({ scrollTo, text }) {
  gsap.registerPlugin(ScrollToPlugin)
  const handleClick = () => {
    if (typeof window !== "undefined") {
      // gsap.to(window, 1, { scrollTo: scrollTo, ease: Sine.easeInOut })
      // document.querySelector(`${scrollTo}`).scrollIntoView({
      //   behavior: "smooth",
      // })
      gsap.to(window, {
        duration: 1,
        scrollTo: { y: `${scrollTo}` },
      })
    }
  }
  return (
    <button
      onClick={handleClick}
      className={`${styles.btnScrollDown} flex justify-center flex-col`}
    >
      <span className="text-white">{text}</span>
      <svg viewBox="0 0 512 512" className="self-center">
        <polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 " />
      </svg>
    </button>
  )
}
