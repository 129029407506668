import React, { Component } from "react"
import IntroVideo from "./IntroVideo"
import Articles from "./Articles"
import Stories from "./Stories"
import Explore from "./Explore"
// import Embrace from "./Embrace"
import Join from "./Join/"
import CTABtn from "../../Button/CTA"

export default class Home extends Component {
  render() {
    const { images, homeContent, location } = this.props
    return (
      <div>
        <IntroVideo homeContent={homeContent} />
        <Articles homeContent={homeContent} />
        <Stories homeContent={homeContent} images={images} />
        <Explore images={images} home={homeContent} />
        {/* <Embrace home={homeContent} /> */}
        <Join home={homeContent} />
        <CTABtn location={location} />
      </div>
    )
  }
}
