import React, { Component } from "react"
import BackgroundImage from "gatsby-background-image"
import styles from "./index.module.scss"
import { StaticQuery, graphql, Link } from "gatsby"

import { useSelector, useDispatch } from "react-redux"

export default function Wrapper() {
  const store = useSelector(state => state)
  const dispatch = useDispatch()

  return <Stories store={store} dispatch={dispatch} />
}

class Stories extends Component {
  render() {
    const { locale } = this.props.store.lang
    const { URL } = this.props.store
    const isChinese = locale === "tc"

    const queryString = URL.queryString ? URL.queryString : ""
    return (
      <StaticQuery
        query={graphql`
          query StoriesQuery {
            freedom: file(name: { eq: "Embrace-Freedom-Landscape" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            adventure: file(
              name: { eq: "Latest-Stories---Embrace-Adventure-1-DESKTOP" }
            ) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            adventureMobile: file(
              name: { eq: "Latest-Stories---Embrace-Adventure-1-MOBILE" }
            ) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            adventure2: file(
              name: { eq: "Latest-Stories---Embrace-Adventure-2-DESKTOP" }
            ) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            independence: file(name: { eq: "Embrace-Independence-Landscape" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            inspiration2: file(
              name: { eq: "Embrace-Inspiration_Music-Live-Banner_3_revised" }
            ) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            inspiration2mobile: file(
              name: { eq: "Embrace-Inspiration_3_Mobile" }
            ) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            inspiration3: file(
              name: { eq: "Embrace-Inspiration_Lululemon-Yoga" }
            ) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            inspiration3mobile: file(
              name: { eq: "Embrace-Inspiration_Lululemon-YogaMobile" }
            ) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            progress3: file(name: { eq: "Embrace-Progress_3-landscape" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            progress4: file(name: { eq: "Embrace Progress_4" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            progress5: file(
              name: { eq: "BMW_90sec_EP05_Rental_cover_desktop" }
            ) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            progress4mobile: file(name: { eq: "Embrace Progress_4-mobile" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            progress6: file(name: { eq: "EP06_16to9_08" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            progress6mobile: file(name: { eq: "EP06_1to1_08" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            wisdom: file(name: { eq: "bimmer-story-header" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => (
          <section id="latest-stories">
            <header className="md:py-10 py-40 text-center">
              <h5 className="large-text light-font md:text-2xl mb-4">
                {isChinese ? "最新動態" : "Latest Stories"}
              </h5>
              <h3 className="headline light-font md:text-3xl uppercase">
                {isChinese ? "同行 · 心悅旅程" : "Along with BMW"}
              </h3>
            </header>
            <div className="md:h-auto h-screen">
              <div className="h-2/3 md:flex-col flex">
                <article
                  className={`article w-8/12 md:w-full h-full border-white border-solid border-b-4 border-r-2 flex relative md:border-r-0 ${styles.article} ${styles.articleWisdom}`}
                >
                  <Link
                    to={
                      isChinese
                        ? "/90-seconds-with-bmw-professional-vol-5-bmw-rental-service" +
                          queryString
                        : "/en/90-seconds-with-bmw-professional-vol-5-bmw-rental-service" +
                          queryString
                    }
                    className="absolute flex w-full h-full"
                  >
                    <div className="bg-container--with-filter absolute top-0 left-0 w-full h-full">
                      <BackgroundImage
                        Tag="div"
                        className="w-full h-full"
                        fluid={data.progress5.childImageSharp.fluid}
                        backgroundColor={`#040e18`}
                      />
                    </div>
                    <div className="text-container md:pb-6 md:px-6 z-10 self-end px-12 pb-12 text-white">
                      <h5 className="bold-font xxl:text-4xl md:text-3xl text-2xl">
                        {isChinese ? "心悅 一瞬千里" : "Embrace Progress"}
                      </h5>
                      <h4 className="light-font xxl:text-5xl md:text-2xl text-3xl">
                        90-Second with BMW Professional Vol. 5 Rental Service
                      </h4>
                    </div>
                  </Link>
                </article>
                <div className="md:flex-row md:w-full flex flex-col w-4/12 h-full">
                  <article
                    className={`article h-1/2 md:w-1/2 border-white border-solid border-l-2 border-b-4 md:border-l-0 md:border-r-2 flex relative ${styles.article} `}
                  >
                    {/* <div
                      onClick={() =>
                        this.props.dispatch({
                          type: "TOGGLE_VIDEO_MODAL",
                          opened: true,
                        })
                      }
                      className="absolute flex w-full h-full"
                    > */}
                    <a
                      href="https://www.youtube.com/watch?v=fv5JnN4IYok"
                      className="absolute flex w-full h-full"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="bg-container--with-filter absolute top-0 left-0 w-full h-full">
                        <BackgroundImage
                          Tag="div"
                          className="md:hidden w-full h-full"
                          fluid={data.inspiration3.childImageSharp.fluid}
                          backgroundColor={`#040e18`}
                        />
                        <BackgroundImage
                          Tag="div"
                          className="md:block hidden w-full h-full"
                          fluid={data.inspiration3mobile.childImageSharp.fluid}
                          backgroundColor={`#040e18`}
                        />
                      </div>
                      <div className="text-container md:pb-6 md:px-6 z-10 self-end px-12 pb-12 text-white">
                        <h5 className="bold-font xxl:text-4xl md:text-3xl text-2xl">
                          {isChinese ? "心悅 啟迪靈感" : "Embrace Inspiration"}
                        </h5>
                        <h4 className="light-font xxl:text-5xl md:text-2xl text-3xl">
                          BMW INSPIRE Relaxing Yoga Stretches
                        </h4>
                        {isChinese ? null : (
                          <p
                            className={`text-lg md:text-xl text-white light-font xxl:text-2xl`}
                          >
                            <sup>*</sup>Conducted in Cantonese only.
                          </p>
                        )}
                      </div>
                    </a>
                    {/* </div> */}
                  </article>
                  <article
                    className={`article h-1/2 md:w-1/2 border-white border-solid border-l-2 border-b-4 flex relative ${styles.article}`}
                  >
                    <Link
                      to={
                        isChinese
                          ? "/along-with-bmw-presents-bmw-drivecation-vol-2-the-3-touring-x-a-couples-retreat-time" +
                            queryString
                          : "/en/along-with-bmw-presents-bmw-drivecation-vol-2-the-3-touring-x-a-couples-retreat-time" +
                            queryString
                      }
                      className="relative flex w-full h-full"
                    >
                      <div className="bg-container--with-filter absolute top-0 left-0 w-full h-full">
                        <BackgroundImage
                          Tag="div"
                          className="w-full h-full"
                          fluid={data.adventure2.childImageSharp.fluid}
                          backgroundColor={`#040e18`}
                        />
                      </div>
                      <div className="text-container md:pb-6 md:px-6 z-10 self-end px-12 pb-12 text-white">
                        <h5 className="bold-font xxl:text-4xl md:text-3xl text-2xl">
                          {isChinese ? "心悅 敢於冒險" : "Embrace Adventure"}
                        </h5>
                        <h4 className="light-font xxl:text-5xl md:text-2xl text-3xl">
                          BMW Drivecation Vol. 2 A Couples Retreat Time
                        </h4>
                      </div>
                    </Link>
                  </article>
                </div>
              </div>
              <div className="h-1/3 md:flex-wrap flex">
                <article
                  className={`article w-4/12 md:w-1/2 h-full border-white border-solid border-b-4 border-r-2 flex relative ${styles.article}`}
                >
                  <Link
                    to={
                      isChinese
                        ? "/90-seconds-with-bmw-professional-vol-6-bmw-service" +
                          queryString
                        : "/en/90-seconds-with-bmw-professional-vol-6-bmw-service" +
                          queryString
                    }
                    //href="https://www.facebook.com/MOOVHK/videos/417601339206654"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="relative flex w-full h-full"
                  >
                    <div className="bg-container--with-filter absolute top-0 left-0 w-full h-full">
                      <BackgroundImage
                        Tag="div"
                        className="md:hidden w-full h-full"
                        fluid={data.progress6.childImageSharp.fluid}
                        backgroundColor={`#040e18`}
                      />
                      <BackgroundImage
                        Tag="div"
                        className="md:block hidden w-full h-full"
                        fluid={data.progress6.childImageSharp.fluid}
                        backgroundColor={`#040e18`}
                      />
                    </div>
                    <div className="text-container md:pb-6 md:px-6 z-10 self-end px-12 pb-12 text-white">
                      <h5 className="bold-font xxl:text-4xl md:text-3xl text-2xl">
                        {isChinese ? "心悅 一瞬千里" : "Embrace Progress"}
                      </h5>
                      <h4 className="light-font xxl:text-5xl md:text-2xl text-3xl">
                        {isChinese
                          ? "90-Seconds with BMW Professional Vol. 6: BMW Service"
                          : "90-Seconds with BMW Professional Vol. 6: BMW Service"}
                        {/* {isChinese
                          ? "立即重溫 MOOV x BMW Empower Your Journey Live"
                          : "Rewatch MOOV x BMW Empower Your Journey Live"} */}
                      </h4>
                    </div>
                  </Link>
                </article>
                <article
                  className={`article w-4/12 md:w-1/2 h-full border-white border-solid border-b-4 border-l-2 border-r-2 md:border-r-0 flex relative ${styles.article}`}
                >
                  <Link
                    to={
                      isChinese
                        ? "/90-seconds-with-bmw-professional-vol-4-bmw-intelligent-personal-assistant" +
                          queryString
                        : "/en/90-seconds-with-bmw-professional-vol-4-bmw-intelligent-personal-assistant" +
                          queryString
                    }
                    className="absolute flex w-full h-full"
                  >
                    <div className="bg-container--with-filter absolute top-0 left-0 w-full h-full">
                      <BackgroundImage
                        Tag="div"
                        className="w-full h-full"
                        fluid={data.progress4.childImageSharp.fluid}
                        backgroundColor={`#040e18`}
                      />
                    </div>
                    <div className="text-container md:pb-6 md:px-6 z-10 self-end px-12 pb-12 text-white">
                      <h5 className="bold-font xxl:text-4xl md:text-3xl text-2xl">
                        {isChinese ? "心悅 一瞬千里" : "Embrace Progress"}
                      </h5>
                      <h4 className="light-font xxl:text-5xl md:text-2xl text-3xl">
                        90-Second with BMW Professional Vol. 4
                        <br />
                        BMW Intelligent Personal Assistant
                      </h4>
                    </div>
                  </Link>
                </article>
                <article
                  className={`article w-4/12 md:w-full h-full border-white border-solid border-b-4 border-l-2 md:border-l-0 flex relative ${styles.article} ${styles.articleIndependence}`}
                >
                  <Link
                    to={
                      isChinese
                        ? "/independence" + queryString
                        : "/en/independence" + queryString
                    }
                    className="relative flex w-full h-full"
                  >
                    <div className="bg-container--with-filter absolute top-0 left-0 w-full h-full">
                      <BackgroundImage
                        Tag="div"
                        className="w-full h-full"
                        fluid={data.independence.childImageSharp.fluid}
                        backgroundColor={`#040e18`}
                      />
                    </div>
                    <div className="text-container md:pb-6 md:px-6 z-10 self-end px-12 pb-12 text-white">
                      <h5 className="bold-font xxl:text-4xl md:text-3xl text-2xl">
                        {isChinese ? "心悅 自主創新" : "Embrace Independence"}
                      </h5>
                      <h4 className="light-font xxl:text-5xl md:text-2xl text-3xl">
                        {isChinese
                          ? "#MYBMWDAY Social Wall"
                          : "#MYBMWDAY Social Wall"}
                      </h4>
                    </div>
                  </Link>
                </article>
              </div>
            </div>
          </section>
        )}
      />
    )
  }
}
